<script setup lang="ts">
import { useUserStore } from '~~/stores/UserStore';

const supabase = useSupabaseClient()
const userStore = useUserStore()
const user = useSupabaseUser()

const links = ref<NavigationItem[]>([])

// Function to identify user in Umami when logged in
function identifyUserInUmami() {
  // Only run in client-side environment and when user exists
  if (process.client && user.value && window.umami) {
    window.umami.identify(user.value.id, {
      email: user.value.email,
      role: userStore.user.role,
      name: userStore.user.name,
      authenticated: true
    })
  }
}

function getLinks() {
  if (!user.value) {
    links.value = [
      { label: 'Login', icon: 'i-heroicons-user-circle', to: '/login' },
      ...Navigation.filter(item => !item.roles)
    ]
    return
  }

  const userRole = userStore.user.role?.value || ''

  if (userRole === Roles.admin!.value) {
    links.value = Navigation
    return
  }

  const hasRequiredRole = (roles?: Role[]) => {
    if (!roles) return true // If no roles specified, everyone can see it
    return roles.some(role => role.value === userRole)
  }
  links.value = Navigation.reduce<NavigationItem[]>((acc, item) => {
    if (!item.roles) {
      acc.push({ ...item })
      return acc
    }
    if (!hasRequiredRole(item.roles)) return acc
    const filteredItem = { ...item }
    if (item.children) {
      const filteredChildren = item.children.filter(child =>
        !child.roles || hasRequiredRole(child.roles)
      )
      if (filteredChildren.length > 0) {
        filteredItem.children = filteredChildren
        acc.push(filteredItem)
      }
    } else {
      acc.push(filteredItem)
    }

    return acc
  }, [])
}
getLinks()


const logout = async () => {
  await useSupabaseClient().auth.signOut()
  useToast().add({ title: 'Success', description: 'Logout successful', color: 'success', icon: 'i-heroicons-check-circle' })
  await navigateTo('/login')
}

const { data } = supabase.auth.onAuthStateChange((event, session) => {
  setTimeout(async () => {
    await userStore.getUser()
    getLinks()
    if (event === 'SIGNED_IN' || event === 'USER_UPDATED') {
      // Need to wait a bit for the Umami script to load
      setTimeout(() => {
        identifyUserInUmami()
      }, 500)
    }
  }, 100)
})

onMounted(() => {
  if (user.value) {
    // Wait for Umami script to load
    setTimeout(() => {
      identifyUserInUmami()
    }, 500)
  }
})
</script>

<template>
  <UDashboardGroup>
    <UDashboardSidebar resizable>
      <template #header>
        <h1 class="font-semibold text-xl text-center w-full text-green-600 dark:text-green-400">
          Project Controls Reporting
        </h1>
      </template>
      <template #default>
        <UNavigationMenu arrow orientation="vertical" :items="links" />
      </template>
      <template #footer>
        <div class="flex flex-row w-full gap-2 items-center justify-center p-1">
          <GlobalOnlineUsersButton />
          <UButton 
            v-if="user" 
            color="error" 
            block 
            icon="i-heroicons-arrow-left-start-on-rectangle" 
            class="w-1/2" 
            @click="logout"
          >
            Logout
          </UButton>
          <UButton 
            v-else 
            color="success" 
            block 
            icon="i-heroicons-arrow-left-end-on-rectangle" 
            class="w-1/2" 
            to="/login"
          >
            Login
          </UButton>
        </div>
      </template>
    </UDashboardSidebar>
    <UDashboardPanel>
      <UDashboardNavbar>
        <template #default>
          <GlobalSearch />
        </template>
        <template #right>
          <ClientOnly>
            <UColorModeSelect />
          </ClientOnly>
        </template>
      </UDashboardNavbar>
      <div class="w-full overflow-y-auto">
        <GlobalFileUploadAlert />
        <div class="px-2 mt-2 pb-4">
          <slot />
        </div>
      </div>
    </UDashboardPanel>
  </UDashboardGroup>
</template>